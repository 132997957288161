<template>
  <modal
    :name="modalName"
    transition="nice-modal-fade"
    :adaptive="true"
    :scrollable="true"
    :reset="true"
    height="auto"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.tourSect.initBtn') }}
      </div>
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ $t('booking.tourSect.initBtn') }}
        </div>
        <div class="subtitle--text">{{ $t('booking.tourSect.initChooseHour') }}</div>
        <time-picker v-model="timeValue" format="HH:mm" step="30"></time-picker>
        <button type="button" class="btn btn--green main--btn" @click="closeModal">
          {{ $t('booking.tourSect.confHourBtn') }}
        </button>
      </section>
      <button type="button" class="close" @click="closeModal" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
const TimePicker = () => import('@/components/utils/time-picker');
import { mapState } from 'vuex';

export default {
  name: 'initiateTourHour',
  components: {
    TimePicker,
  },
  computed: {
    ...mapState({
      listing: (state) => state.listing.listing,
      to: (state) => state.v2.rentTransaction.initiateTour.to,
      from: (state) => state.v2.rentTransaction.initiateTour.from,
    }),
    tourDateView() {
      const tourDate = this.$store.state.v2.rentTransaction.initiateTour.date;
      return this ? this.$date.format(tourDate, 'dd MMMM yyyy') : null;
    },
    timeValue: {
      get() {
        // console.log('Time value: ', this.$store.state.booking.initiateTour.time);
        return this.$store.state.v2.rentTransaction.initiateTour.time;
      },
      set(value) {
        this.$store.commit('v2/rentTransaction/initiateTour/SET_TIME', value);
      },
    },
  },
  data: () => ({
    modalName: 'modal--initiate-tour-2',
  }),
  methods: {
    async reqTour() {
      // fbq('track', 'Schedule');
      try {
        await this.$store.dispatch('v2/rentTransaction/initiateTour/submit');
        this.$emit('actionDone');
        this.closeModal();
      } catch (e) {
        console.log('ERROR REQUEST TOUR: ', e);
      }
    },
    closeModal() {
      this.$modal.hide(this.modalName);
    },
    close() {
      this.closeModal();
      this.$modal.show('modal--initiate-tour-1', {
        to: this.to,
        from: this.from,
      });
    },
  },
};
</script>

<style scoped>
.scrollbar {
  height: auto;
  margin: 0;
  max-height: 158px;
  overflow: scroll;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.btn-group-tour {
  float: none;
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.btn-group-tour .btn {
  margin-top: -1px;
}
</style>
